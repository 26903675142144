.svg {
  height: 100%;
  width: 100%;
  animation: rotate 2s linear infinite;
  transform-origin: center center;
}

.svg circle {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  animation:
    dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: rgba(255, 255, 255, 1);
  }
  40% {
    stroke: rgba(255, 255, 255, 0.7);
  }
  66% {
    stroke: rgba(255, 255, 255, 0.5);
  }
  80%,
  90% {
    stroke: rgba(255, 255, 255, 0.2);
  }
}
@keyframes color {
  0% {
    stroke: rgba(255, 255, 255, 1);
  }
  40% {
    stroke: rgba(255, 255, 255, 0.7);
  }
  66% {
    stroke: rgba(255, 255, 255, 0.5);
  }
  80%,
  90% {
    stroke: rgba(255, 255, 255, 0.2);
  }
}
