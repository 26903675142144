.errorgradientdown {
  background-color: theme('colors.mainBgBlueC2');
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 50%,
      theme('colors.mainBgBlueC2') 70%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
}
