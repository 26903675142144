.circle1 {
  animation: fade-in-first 4s linear infinite forwards;
}

.circle2 {
  animation: fade-in-second 4s linear infinite forwards;
}

@keyframes fade-in-first {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 100%;
  }
  95% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

@keyframes fade-in-second {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  95% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}
