.range {
  -webkit-appearance: none;
  background: rgba(146, 146, 146, 0.55);
  border-radius: 6px;
  background-image: linear-gradient(#1a2436, #1a2436);
  background-size: 70% 100%;
  height: 4px;
  background-repeat: no-repeat;
}

/* shadow DOM object styles accept only a single selector, so we list them ony by one */
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  border: none;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.56);
  transition: background 0.3s ease-in-out;
}

.range::-ms-thumb {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  border: none;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.56);
  transition: background 0.3s ease-in-out;
}

.range::-moz-range-thumb {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  border: none;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.56);
  transition: background 0.3s ease-in-out;
}

.range::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.range::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.range::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
